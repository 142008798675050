import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineWarning,
  AiOutlineCloseCircle,
} from "react-icons/ai"; // react-icons 임포트

// Toast Context 생성
const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success"); // type 상태 추가
  const [isAnimating, setIsAnimating] = useState(false); // 애니메이션 상태 추가
  const timerRef = useRef(null); // 타이머 참조

  // 3초 후 자동으로 토스트 사라지게 설정
  useEffect(() => {
    if (showToast) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        setIsAnimating(true); // 닫힘 애니메이션 시작
        setTimeout(() => {
          setShowToast(false); // 실제로 닫힘 처리
          setIsAnimating(false); // 애니메이션 초기화
        }, 500); // 닫힘 애니메이션 완료 시간
      }, 3000);

      return () => clearTimeout(timerRef.current);
    }
  }, [showToast]);

  // 성공 토스트
  const toastSuccess = (message) => {
    triggerToast(message, "success");
  };

  // 경고 토스트
  const toastWarning = (message) => {
    triggerToast(message, "warning");
  };

  // 오류 토스트
  const toastError = (message) => {
    triggerToast(message, "error");
  };

  // 토스트 메시지 트리거 함수
  const triggerToast = (message, type) => {
    if (showToast) {
      // 기존 토스트를 닫는 애니메이션 없이 바로 새로운 토스트로 교체
      setIsAnimating(false);
      setShowToast(false);
      setToastMessage(message);
      setToastType(type);
      setTimeout(() => {
        setShowToast(true);
      }, 100); // 약간의 지연 후 새로운 메시지 표시
    } else {
      // 바로 새로운 메시지 표시
      setToastMessage(message);
      setToastType(type);
      setShowToast(true);
    }
  };

  return (
    <ToastContext.Provider value={{ toastSuccess, toastWarning, toastError }}>
      {children}
      {showToast && (
        <ToastMessage
          setToast={setShowToast}
          type={toastType}
          text={toastMessage}
          isAnimating={isAnimating}
        />
      )}
    </ToastContext.Provider>
  );
};

// ToastMessage 컴포넌트 (성공, 경고, 실패에 따른 배경색 및 아이콘 추가)
const ToastMessage = ({ setToast, type, text, isAnimating }) => {
  // type에 따라 다른 아이콘을 사용
  const Icon =
    type === "success"
      ? AiOutlineCheckCircle
      : type === "warning"
      ? AiOutlineWarning
      : AiOutlineCloseCircle; // 오류는 CloseCircle 아이콘

  const backgroundColor =
    type === "success"
      ? "bg-primary-400 text-primary-800" // 성공
      : type === "warning"
      ? "bg-yellow-400 text-yellow-800" // 경고
      : "bg-red-400 text-red-800"; // 실패

  const textColor =
    type === "success"
      ? "text-primary-800" // 성공
      : type === "warning"
      ? "text-yellow-800" // 경고
      : "text-red-800"; // 실패

  return (
    <>
      <style>
        {`
          @keyframes slideDown {
            0% {
              transform: translate(-50%, -50px); /* X축 중앙 정렬 고정, Y축만 이동 */
              opacity: 0;
            }
            100% {
              transform: translate(-50%, 0); /* 최종 위치 (중앙) */
              opacity: 1;
            }
          }
          @keyframes slideUp {
            0% {
              transform: translate(-50%, 0); /* 최종 위치 */
              opacity: 1;
            }
            100% {
              transform: translate(-50%, -50px); /* 상단으로 올라감 */
              opacity: 0;
            }
          }
        `}
      </style>
      <div
        className={`fixed top-8 left-1/2 transform -translate-x-1/2 px-5 py-4 rounded-lg shadow-xl z-50 inline-flex items-center bg-opacity-70 backdrop-blur ${backgroundColor}`}
        style={{
          animation: `${isAnimating ? "slideUp" : "slideDown"} 0.5s ease-out`,
          maxWidth: "80%",
          opacity: isAnimating ? 0 : 1, // 애니메이션 종료 후 투명도 설정
        }}
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <Icon
              className="mr-3 text-2xl"
              style={{ position: "relative", top: "-1px" }}
            />
            <p
              className={`grow shrink basis-0 text-lg font-bold ${textColor}`}
            >
              {text}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToastMessage;
